<!--个人中心页面-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;align-items: center;justify-content: space-between;">
      <span style="font-size: 24px;font-weight: 500;color: #707070;">个人中心</span>
      <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
    </div>

    <!--订单数据总览-->
    <el-card shadow="never" style="margin-top: 20px;">
      <div style="display: flex;align-items: center;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">订单数据总览</span>
      </div>

      <el-row :gutter="15" style="margin-top: 30px;">
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon1.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待接单</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.pending_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon2.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待派单</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.dispatch_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon3.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待完成</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.tobecompleted_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon4.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">异常订单</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.abnormal_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon5.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待回访</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.waitreturn_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon6.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待审核</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.waittrial_orders_count}}</span>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="15" style="margin-top: 30px;">
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon7.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待初审</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.firsttrial_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon8.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待复审</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.reexamine_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon9.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待确认</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.confirm_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon10.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待对账</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.reconciled_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon11.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">待结算</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.settled_orders_count}}</span>
          </div>
        </el-col>
        <el-col :span="4" style="text-align: center;">
          <div style="display: flex;justify-content: space-evenly;">
            <div style="display: flex;align-items: center;">
              <img src="@/assets/images/home/gerenzhongxin/icon12.png" alt="" style="width: 16px;height: 17px;margin-right: 5px;">
              <span style="font-size: 16px;font-weight: 400;color: #868792;">已核销</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <span style="font-size: 30px;font-weight: 700;color: #3f4155;">{{statistics.verified_orders_count}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!--我的订单-->
    <el-card shadow="never">
      <div style="display: flex;align-items: center;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">我的订单</span>
      </div>
      <div style="margin-top: 20px;">
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :headerCellStyle="headerCellStyle"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
        >
        </my-table>
      </div>
    </el-card>

    <!--我的考核-->
    <el-card shadow="never">
      <div style="display: flex;align-items: center;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">我的考核</span>
      </div>
      <div style="margin-top: 20px;">
        <my-table
            ref="myTable"
            :columns="assessmentcolumns"
            :tableData="assessmentList"
            :headerCellStyle="assessmentheaderCellStyle"
            :total="assessmenttotal"
            :customsFromWhere="assessmentwhere"
            :loading="assessmentloading"
            @select="assessmentselect"
            @ChangeSize="assessmentChangeSize"
            @currentChange="assessmentcurrentChange"
        >
        </my-table>
      </div>
    </el-card>

    <!--我的提现-->
    <!--<el-card shadow="never">-->
    <!--  <div style="display: flex;align-items: center;">-->
    <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
    <!--    <span style="font-size: 20px;font-weight: 700;color: #202033;">我的提现</span>-->
    <!--  </div>-->
    <!--  <div style="margin-top: 20px;">-->
    <!--    <my-table-->
    <!--        ref="myTable"-->
    <!--        :columns="withdrawalcolumns"-->
    <!--        :tableData="withdrawalList"-->
    <!--        :headerCellStyle="withdrawalheaderCellStyle"-->
    <!--        :total="withdrawaltotal"-->
    <!--        :customsFromWhere="withdrawalwhere"-->
    <!--        :loading="withdrawalloading"-->
    <!--        @select="withdrawalselect"-->
    <!--        @ChangeSize="withdrawalChangeSize"-->
    <!--        @currentChange="withdrawalcurrentChange"-->
    <!--    >-->
    <!--    </my-table>-->
    <!--  </div>-->
    <!--</el-card>-->

    <!--我的在线时长-->
    <el-card shadow="never">
      <div>
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;">我的在线时长</span>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <el-date-picker
              v-model="onLinewhere.year"
              value-format="yyyy"
              clearable
              @change="selectyear"
              type="year"
              placeholder="选择年">
          </el-date-picker>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <my-table
            ref="myTable"
            :columns="onLinecolumns"
            :tableData="onLineList"
            :headerCellStyle="onLineheaderCellStyle"
            :total="onLinetotal"
            :customsFromWhere="onLinewhere"
            :loading="onLineloading"
            @select="onLineselect"
        >
        </my-table>
      </div>
    </el-card>

  </div>
</template>

<script>
  // 引入的接口
  import {get_my_kpi, get_my_online_duration, get_my_order_list, get_personal_center_Statistics} from "@/api/center";

  export default {
    data(){
      return{
        //顶部统计
        statistics:{},

        //我的订单表格数据
        // 表格搜索条件
        where: {
          page: 1, // 当前页数
          limit: 5 // 每页显示条目个数
        },
        // 表格数据
        List: [],
        // 总条目数
        total: 0,
        //加载动画
        loading:true,
        // 表头数据
        columns: [
          {
            type: "selection",
            isShow: true
          },
          {
            label: "车牌号",
            prop: "license_plate",
            isShow: true
          },
          {
            label: "案件号",
            prop: "case_number",
            isShow: true
          },
          {
            label: "订单号",
            prop: "odd",
            isShow: true
          },
          {
            label: "项目",
            prop: "rescue_type_msg",
            isShow: true
          },
          {
            label: "下单时间",
            prop: "create_time",
            isShow: true
          },
          {
            label: "状态",
            prop: "order_status_msg",
            isShow: true
          },
          {
            label: "服务人员",
            prop: "driver_name",
            isShow: true
          },
          {
            label: "订单来源",
            prop: "facilitator_name",
            isShow: true
          },
          {
            label: "发包方",
            prop: "channel_name",
            isShow: true
          },
          {
            label: "项目金额",
            prop: "client_price",
            isShow: true
          },
          {
            label: "支出金额",
            prop: "facilitator_price",
            isShow: true
          },
        ],

        //考核表格数据
        assessmentwhere: {
          page: 1, // 当前页数
          limit: 5 // 每页显示条目个数
        },
        // 表格数据
        assessmentList: [],
        // 总条目数
        assessmenttotal: 0,
        //加载动画
        assessmentloading:true,
        // 表头数据
        assessmentcolumns: [
          {
            type: "selection",
            isShow: true
          },
          {
            label: "编号",
            prop: "id",
            isShow: true
          },
          {
            label: "订单号",
            prop: "odd",
            isShow: true
          },
          {
            label: "考核项目",
            prop: "items_name",
            isShow: true
          },
          {
            label: "考核人",
            prop: "assessor",
            isShow: true
          },
          {
            label: "考核结果",
            prop: "annotation",
            isShow: true
          },
          {
            label: "奖罚金额",
            prop: "amount",
            isShow: true
          },
          {
            label: "触发时间",
            prop: "execution_time",
            isShow: true
          },
        ],

        //我的提现
        withdrawalwhere: {
          page: 1, // 当前页数
          limit: 5 // 每页显示条目个数
        },
        // 表格数据
        withdrawalList: [],
        // 总条目数
        withdrawaltotal: 0,
        //加载动画
        withdrawalloading:true,
        // 表头数据
        withdrawalcolumns: [
          {
            type: "selection",
            isShow: true
          },
          {
            label: "提现单号",
            prop: "id",
            isShow: true
          },
          {
            label: "提现申请时间",
            prop: "facilitator_addres",
            isShow: true
          },
          {
            label: "提现金额",
            prop: "facilitator_addres",
            isShow: true
          },
          {
            label: "提现到账时间",
            prop: "facilitator_addres",
            isShow: true
          },
          {
            label: "提现类型",
            prop: "authentication_status",
            isShow: true
          },
          {
            label: "提现状态",
            prop: "status",
            isShow: true
          },
        ],

        //我的在线时长
        onLinewhere: {
          // page: 1, // 当前页数
          // limit: 5 // 每页显示条目个数
        },
        // 表格数据
        onLineList: [],
        // 总条目数
        onLinetotal: 0,
        //加载动画
        onLineloading:true,
        // 表头数据
        onLinecolumns: [
          {
            type: "selection",
            isShow: true
          },
          {
            label: "年月",
            prop: "months",
            isShow: true
          },
          {
            label: "在线时长（分钟）",
            prop: "bind_duration",
            isShow: true
          },
        ],


      }
    },

    mounted() {
      // 顶部统计
      this.getTongji();

      //调用获取我的订单列表数据方法
      this.getList();

      //调用我的考核
      this.getList1();

      //调用我的在线时长
      this.getList4();
    },

    methods:{
      //个人中心统计
      getTongji(){
        get_personal_center_Statistics().then(res => {
          this.statistics = res.data;
        })
      },

      // 获取我的订单列表
      getList(){
        get_my_order_list(this.where).then(async (res) => {
          //我的订单
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        })
      },
      // 当前页数改变事件
      currentChange(val) {
        console.log("当前页数", val)
        this.where.page = val;
        this.getList();
      },
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      select(selection, row) {
        console.log(selection)
        console.log("选择的所有数据行", selection)
        console.log("当前选择的数据行", row)
      },
      ChangeSize(val){
        console.log('当前页面显示的条数',val);
        this.where.limit = val;
        this.getList()
      },
      // 表头单元格的 style 的回调方法
      headerCellStyle({ row, column, rowIndex, columnIndex }) {
        return "background: #F7F8FA"
      },


      //我的考核表格事件
      getList1(){
        // //我的提现
        // this.withdrawaltotal = res.data.count;
        // this.withdrawalList = res.data.list;
        // this.withdrawalloading = false;
        //
        get_my_kpi(this.assessmentwhere).then(async (res) => {
          //我的考核
          this.assessmenttotal = res.data.count;
          this.assessmentList = res.data.list;
          this.assessmentloading = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.assessmentloading = false;
        })
      },
      // 当前页数改变事件
      assessmentcurrentChange(val) {
        console.log("当前页数", val)
        this.assessmentwhere.page = val;
        this.getList1();
      },
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      assessmentselect(selection, row) {
        console.log(selection)
        console.log("选择的所有数据行", selection)
        console.log("当前选择的数据行", row)
      },
      assessmentChangeSize(val){
        console.log('当前页面显示的条数',val);
        this.assessmentwhere.limit = val;
        this.getList1()
      },
      // 表头单元格的 style 的回调方法
      assessmentheaderCellStyle({ row, column, rowIndex, columnIndex }) {
        return "background: #F7F8FA"
      },

      //我的提现表格事件
      // 当前页数改变事件
      withdrawalcurrentChange(val) {
        console.log("当前页数", val)
        this.where.page = val;
        this.getList();
      },
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      withdrawalselect(selection, row) {
        console.log(selection)
        console.log("选择的所有数据行", selection)
        console.log("当前选择的数据行", row)
      },
      withdrawalChangeSize(val){
        console.log('当前页面显示的条数',val);
        this.where.limit = val;
        this.getList()
      },
      // 表头单元格的 style 的回调方法
      withdrawalheaderCellStyle({ row, column, rowIndex, columnIndex }) {
        return "background: #F7F8FA"
      },

      // 我的在线时长
      getList4(){
        get_my_online_duration(this.onLinewhere).then(res => {
          //我的在线时长
          this.onLinetotal = res.data.count;
          this.onLineList = res.data.list;
          this.onLineloading = false;
        })
      },
      selectyear(value){
        this.onLinewhere.year = value;
        this.getList4();
      },
      //我的在线时长表格事件
      onLinecurrentChange(val) {
        console.log("当前页数", val)
        this.where.page = val;
        this.getList();
      },
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      onLineselect(selection, row) {
        console.log(selection)
        console.log("选择的所有数据行", selection)
        console.log("当前选择的数据行", row)
      },
      onLineChangeSize(val){
        console.log('当前页面显示的条数',val);
        this.where.limit = val;
        this.getList()
      },
      // 表头单元格的 style 的回调方法
      onLineheaderCellStyle({ row, column, rowIndex, columnIndex }) {
        return "background: #F7F8FA"
      },

      //点击返回按钮
      returnPage(){
        this.$router.go(-1);
      },


    }

  }
</script>

<style scoped lang="scss">

</style>
