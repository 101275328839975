import request from '@/utils/request'

/**
 * 个人中心 顶部统计
 * @returns {*}
 */
export function get_personal_center_Statistics (params) {
  return request({
    url: 'capacity/facilitator/get_personal_center_Statistics',
    method: 'get',
    params
  })
}

/**
 * 个人中心 我的订单
 * @returns {*}
 */
export function get_my_order_list (params) {
  return request({
    url: 'capacity/facilitator/get_my_order_list',
    method: 'get',
    params
  })
}
/**
 * 个人中心 我的考核接口
 * @returns {*}
 */
export function get_my_kpi (params) {
  return request({
    url: 'capacity/facilitator/get_my_kpi',
    method: 'get',
    params
  })
}

/**
 * 个人中心 我的在线时长
 * @returns {*}
 */
export function get_my_online_duration (params) {
  return request({
    url: 'capacity/facilitator/get_my_online_duration',
    method: 'get',
    params
  })
}

/**
 * 公共 会员栏目接口
 * @returns {*}
 */
export function member_list (params) {
  return request({
    url: 'common/member_list',
    method: 'get',
    params
  })
}


/**
 * 会员购买支付接口
 * @returns {*}
 */
export function member_pay (data) {
  return request({
    url: `common/member_pay`,
    method: 'post',
    data
  })
}
